<template>
  <div class="videoModuleItem">
    <PullRefresh
      :disabled="isRefreshDisable"
      v-if="!isLoading"
      :loading="loading"
      :refreshing="refreshing"
      :finished="finished"
      @onLoad="onLoad"
      @onRefresh="onRefresh"
      :isNoData="isNoData"
      :error="error"
      :hasAdvPagination="true"
    >
      <div class="swiperBox" v-if="bannerList && bannerList.length">
        <van-swipe class="swipe" :autoplay="3000" indicator-color="#FECD55">
          <van-swipe-item
            v-for="(item, index) in bannerList"
            :key="index"
            @click="goAdv(item)"
          >
            <ImgDecypt :src="item.cover" :key="item.cover" />
          </van-swipe-item>
        </van-swipe>
      </div>

      <div class="notice" v-if="marqueeText">
        <img src="@/assets/png/notice.png" />
        <van-notice-bar
          class="noticeBar"
          speed="20"
          color="rgb(0,0,0)"
          background="rgba(0,0,0,0)"
          :text="marqueeText"
        />
      </div>

      <div class="barTopic" v-if="jingangArea">
        <div
          class="barTopicItem"
          v-for="topicItem in jingangArea"
          :key="topicItem.id"
          @click="goAdv(topicItem)"
        >
          <ImgDecypt
            class="topicImg"
            :src="topicItem.img"
            :key="topicItem.img"
          />
          <p>
            <span>{{ topicItem.name }}</span>
          </p>
        </div>
      </div>

      <div v-for="(item, index) in videoList" :key="index">
        <JustLooking :data="justLookData" />
        <!-- 四宫格(横) -->
        <template v-if="item.showType === 0">
          <VerticleThree :videoInfoData="item" :num="6"></VerticleThree>
        </template>
        <template v-if="item.showType === 4">
          <VerticleThree :videoInfoData="item" :num="3"></VerticleThree>
        </template>
        <!--        <VerticleThree-->
        <!--          :videoInfoData="item"-->
        <!--          :num="6"-->
        <!--        ></VerticleThree>-->
        <!--        <VerticleTwo-->
        <!--          :videoInfoData="item"-->
        <!--        ></VerticleTwo>-->
        <!--        <TransverselyTwo-->
        <!--          :videoInfoData="item"-->
        <!--        ></TransverselyTwo>-->
        <!--        <TransverselyFive-->
        <!--          :videoInfoData="item"-->
        <!--        ></TransverselyFive>-->
        <!--        <TransverselyTwo-->
        <!--          :videoInfoData="item"-->
        <!--          :num="6"-->
        <!--        >-->
        <!--        </TransverselyTwo>-->
      </div>
    </PullRefresh>
  </div>
</template>
<script>
import PullRefresh from "@/components/PullRefresh/index.vue";
// import FourGridHorizontal from "@/components/FourGridHorizontal/index.vue";
import { querySubModuleList } from "@/api/app";
import {
  AdType,
  getAdItem,
  getMarquee,
  jumpAdv,
  MarqueeType,
} from "@/utils/getConfig";
import ImgDecypt from "@/components/ImgDecypt/index.vue";
import TransverselyFive from "@/components/VideoList/TransverselyFive.vue";
import VerticleTwo from "@/components/VideoList/VerticleTwo.vue";
import TransverselyTwo from "@/components/VideoList/TransverselyTwo.vue";
import VerticleThree from "@/components/VideoList/VerticleThree.vue";
import JustLooking from "@/components/VideoList/justLooking.vue";
export default {
  name: "VideoModuleItem",
  props: {
    subModuleId: {
      type: String,
    },
  },
  components: {
    VerticleThree,
    // TransverselyTwo, VerticleTwo, TransverselyFive,
    PullRefresh,
    // FourGridHorizontal,
    ImgDecypt,
    JustLooking,
  },
  data() {
    return {
      pageNumber: 1,
      pageSize: 10,
      isRefreshDisable: false,
      isLoading: true,
      loading: true,
      refreshing: false,
      finished: false,
      isNoData: false,
      error: false,
      videoList: [],
      bannerList: [],
      jingangArea: [],
      marqueeText: null,
      justLookData: {
        hasNext: true,
        allSection: null,
      }, // 用于随便看看的判断数据
    };
  },
  mounted() {
    this.querySubModuleList();
    this.bannerList = getAdItem(AdType.COMMUNITY_RECOMMEND);
    let appConfig = this.$store.getters.appConfig;
    this.marqueeText = getMarquee(MarqueeType.community);
    this.jingangArea = appConfig.jingangArea.list || [];
  },
  methods: {
    goAdv(item) {
      jumpAdv(item);
    },
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.pageNumber++;
      }
      this.querySubModuleList();
    },
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.querySubModuleList("refresh");
    },
    async querySubModuleList(type) {
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        subModuleId: this.subModuleId,
      };
      let res = await this.$Api(querySubModuleList, req);
      this.loading = false;
      this.refreshing = false;
      this.isLoading = false;
      const list = res.data.allSection || [];
      console.log(list);
      if (type === "refresh") {
        this.videoList = list;
      } else {
        this.videoList = this.videoList.concat(list);
      }
      if (this.pageNumber === 1 && this.videoList.length === 0) {
        this.isNoData = true;
      }
      this.justLookData.hasNext = res.data.hasNext;
      if (!res.data.hasNext) {
        this.finished = true;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.videoModuleItem {
  height: 100%;

  .swiperBox {
    .swipe {
      width: 100vw;
      height: calc(100vw / 2.4);

      .van-swipe-item {
        width: 100%;
        color: #fff;
        font-size: 20px;
        line-height: 150px;
        text-align: center;
        background-color: rgb(43, 39, 72);
      }

      /deep/ .van-swipe__indicators {
        left: auto;
        right: 0;
        align-items: center;
      }

      /deep/ .van-swipe__indicator {
        width: 6px;
        height: 6px;
        background-color: rgba(148, 214, 218, 0.3);
      }

      /deep/ .van-swipe__indicator--active {
        width: 9px;
        height: 9px;
        background-color: #94d6da !important;
      }

      /deep/ img {
        object-fit: fill !important;
      }
    }
  }

  .notice {
    padding-left: 16px;
    background: #ffffff;
    color: #999999;
    height: 36px;
    //border-radius: 6px;
    display: flex;
    align-items: center;

    img {
      width: 16px;
      height: 16px;
    }

    /deep/ .van-notice-bar {
      padding: 0;
      font-size: 12px;
    }

    .noticeBar {
      width: 100%;
      height: 100%;
      color: #999999 !important;
      padding: 0 16px 0 10px;
    }
  }

  .barTopic {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    // justify-content: space-between;
    padding: 16px 0;
    font-size: 12px;
    color: #777777;
    text-align: center;
    box-sizing: border-box;
    overscroll-behavior: contain;
    .barTopicItem {
      width: 50px;
      flex: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-right: 6px;
      margin-left: 16px;
    }
    .barTopicItem:last-child {
      padding-right: 30px;
    }

    .topicImg {
      width: 50px;
      height: 50px;
      border-radius: 20px;
      box-shadow: 5px 5px 20px 0px #aeaec080;
      margin-bottom: 16px;
    }

    img {
      width: 42px;
      height: 42px;
    }
  }
}
</style>
