<template>
  <div class="listItem">
    <PullRefresh
      :disabled="isRefreshDisable"
      v-if="!isLoading"
      :loading="loading"
      :refreshing="refreshing"
      :finished="finished"
      @onLoad="onLoad"
      @onRefresh="onRefresh"
      :isNoData="isNoData"
      :error="error"
      :hasAdvPagination="false"
    >
      <!-- 轮播图5个 -->
      <div
        class="fiveGrid"
        v-if="isBanner && bannerList && bannerList.length"
      >
        <div
          class="rectAdvBox"
          @click="goAdv(item)"
          v-for="item in bannerList.slice(0, 10)"
          :key="item.id"
        >
          <ImgDecypt class="rectAdvIcon" :src="item.cover" />
          <div class="rectName">{{ item.title }}</div>
        </div>
      </div>

      <div class="notice" v-if="isNotice && marqueeText.content" @click="goAdv(marqueeText)">
        <img src="~@/assets/png/notice.png" />
        <van-notice-bar
          :key="isNotice"
          class="noticeBar"
          speed="20"
          color="rgb(0,0,0)"
          background="rgba(0,0,0,0)"
          :text="marqueeText.content"
        />
      </div>

      <div class="threeBox" v-if="showType == 3">
        <HorizontalVideoBox
          class="videoBox"
          :videoInfo="item"
          :videoList="videoList"
          v-for="item in videoList"
          :key="item.id"
          :isAcg="isAcg"
        />
      </div>
      <div class="rowsBox" v-else>
        <HorizontalVideoBox
          class="videoBox"
          :videoInfo="item"
          :currentIndex="index"
          :videoList="videoList"
          v-for="(item, index) in videoList"
          :key="item.id"
          :isAcg="isAcg"
        />
      </div>
    </PullRefresh>
    <Loading v-else />
  </div>
</template>
<script>
import PullRefresh from "@/components/PullRefresh/index.vue";
import { querySubModuleList } from "@/api/app";
import {
  AdType,
  getAdItem,
  getMarqueeData,
  jumpAdv,
  MarqueeType,
} from "@/utils/getConfig";
import HorizontalVideoBox from "@/components/HorizontalVideoBox/index.vue";
import NoData from "@/components/NoData/index.vue";
import AdvSwiper from "@/components/AdvSwiper/index.vue";
import OricilegeInfo from "@/views/movies/widget/oricilegeInfo.vue";
import Loading from "@/components/Loading/index.vue";
import ImgDecypt from "@/components/ImgDecypt/index.vue";
// import {List, NoticeBar, PullRefresh} from 'vant';

export default {
  name: "VideoListItem",
  props: {
    index: {
      type: Number,
      default: 0,
    },
    moduleName: {
      type: String,
    },
    subModuleId: {
      type: String,
    },
    moduleSort: {
      type: Number,
    },
    showType: {
      type: Number,
    },
    isAcg: {
      type: Boolean,
    },
    isBanner: {
      type: Boolean,
      default: true,
    },
    isNotice: {
      type: Number,
    },
  },
  components: {
    ImgDecypt,
    Loading,
    // [List.name]: List,
    // [PullRefresh.name]: PullRefresh,
    // NoData,
    HorizontalVideoBox,
    PullRefresh,
  },
  data() {
    return {
      pageNumber: 1,
      pageSize: 10,
      isRefreshDisable: false,
      isLoading: true,
      loading: true,
      refreshing: false,
      finished: false,
      isNoData: false,
      error: false,
      videoList: [],
      bannerList: [],
      marqueeText: null,
    };
  },
  mounted() {
    this.bannerList = getAdItem(AdType.HOMEADV);
    if (this.isNotice) {
      this.marqueeText = getMarqueeData(MarqueeType.community);
      // console.log(this.marqueeText);
    }
    this.querySubModuleList("refresh");
  },
  methods: {
    goAdv(item) {
      jumpAdv(item);
    },
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.pageNumber++;
      }
      this.querySubModuleList();
    },
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.querySubModuleList("refresh");
    },
    async querySubModuleList(type) {
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        subModuleId: this.subModuleId,
        moduleSort: this.moduleSort || 1,
      };
      let res = await this.$Api(querySubModuleList, req);
      console.log(res);
      this.loading = false;
      this.refreshing = false;
      this.isLoading = false;
      const list = this.isAcg
        ? res.data.allMediaInfo
        : res.data.allVideoInfo || [];
      if (type === "refresh") {
        this.videoList = list;
      } else {
        this.videoList = this.videoList.concat(list);
      }
      if (this.pageNumber === 1 && this.videoList.length === 0) {
        this.isNoData = true;
      }
      if (!res.data.hasNext) {
        this.finished = true;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.listItem {
  height: 100%;

  .fiveGrid {
    padding: 0 10px;
    margin-top: 18px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-row-gap: 20px;
    grid-column-gap: 12px;
    .rectAdvBox {
      width: 60px;
      .rectAdvIcon {
        height: 60px;
        width: 60px;
        border-radius: 8px;
        overflow: hidden;
      }
      .rectName {
        font-size: 12px;
        color: #333;
        margin-top: 4px;
        overflow: hidden;
        white-space: nowrap;
        text-align: center;
      }
    }
  }

  .notice {
    margin: 20px 10px 0 10px;
    padding-left: 16px;
    background: #ffffff;
    color: #999999;
    height: 36px;
    //border-radius: 6px;
    display: flex;
    align-items: center;

    img {
      width: 16px;
      height: 16px;
    }

    /deep/ .van-notice-bar {
      padding: 0;
      font-size: 12px;
    }

    .noticeBar {
      width: 100%;
      height: 100%;
      color: #999999 !important;
      padding: 0 16px 0 10px;
    }
  }

  .threeBox {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 8px;
    //flex-wrap: wrap;
    //justify-content: space-between;
    padding: 0 10px;
    margin-top: 12px;
    .videoBox {
      width: 113px;
      margin-bottom: 12px;
      //margin-top: 8px;
      /deep/ .cover {
        height: 166px;
      }
    }
  }

  .rowsBox {
    margin-top: 12px;
    padding: 0 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .videoBox {
      width: 174px;
      margin-bottom: 12px;
      /deep/ .cover {
        height: 254px;
      }
      //margin-top: 12px;
    }
  }
}
</style>
