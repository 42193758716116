<template>
  <div class="home">
    <div class="tab-container">
      <div class="tab-header" :class="isScroll ? '' : 'scrollHeaderBox'">
        <div class="tab-left">
          <div
            class="tab-item"
            v-for="(item, index) in tabs"
            :key="index"
            :class="{ active: index == homeTabIndex }"
            @click="changeModule(index)"
          >
            <img
              class="ActiveIcon"
              v-if="item.title === '漫画' && homeTabIndex == index"
              src="@/assets/png/animationActiveIcon.png"
              alt=""
            />
            <img
              class="Icon"
              v-else-if="item.title === '漫画'"
              src="@/assets/png/animationIcon.png"
              alt=""
            />

            <img
              class="ActiveIcon"
              v-if="item.title === '动漫' && homeTabIndex == index"
              src="@/assets/png/caricatureActiveIcon.png"
              alt=""
            />
            <img
              class="Icon"
              v-else-if="item.title === '动漫'"
              src="@/assets/png/caricatureIcon.png"
              alt=""
            />

            <img
              class="ActiveIcon"
              v-if="item.title === '小说' && homeTabIndex == index"
              src="@/assets/png/novelActiveIcon.png"
              alt=""
            />
            <img
              class="Icon"
              v-else-if="item.title === '小说'"
              src="@/assets/png/novelIcon.png"
              alt=""
            />
          </div>
        </div>
        <div class="tab-right">
          <div class="ranking" @click="$router.push('/searchPage')">
            <SvgIcon iconClass="ranking" class="icon"></SvgIcon>
          </div>
          <div class="ranking" @click="$router.push('historyRecord')">
            <SvgIcon iconClass="welfare" class="icon"></SvgIcon>
          </div>
          <div class="ranking" @click="$router.push('/categoriesPage')">
            <SvgIcon iconClass="classify" class="icon"></SvgIcon>
          </div>
        </div>
      </div>
      <div class="tab-content">
        <!--        <transition name="fade">-->
        <keep-alive>
          <CaricatureTab
            class="mainBox"
            v-if="homeTabIndex == 0"
            :title="moduleName"
            @changeStatus="changeStatus"
            :class="isScroll ? '' : 'scrollHeaderBox'"
          />
          <AnimationTab
            class="mainBox"
            v-if="homeTabIndex == 1"
            :title="moduleName"
            @changeStatus="changeStatus"
            :class="isScroll ? '' : 'scrollHeaderBox'"
          />
          <NovelTab
            class="mainBox"
            v-if="homeTabIndex == 2"
            :title="moduleName"
            @changeStatus="changeStatus"
            :class="isScroll ? '' : 'scrollHeaderBox'"
          />
        </keep-alive>
        <!--        </transition>-->
      </div>
    </div>
    <!-- 9 宫格广告 -->
    <AdvSudoku
      :advList="advSudokuList"
      :show="advSudokuShow"
      @close="advSudokuClose"
    />

    <!-- 系统公告 -->
    <AnnouncementText
      :advList="announcementTextList"
      :show="announcementTextShow"
      @close="announcementTextClose"
    />

    <!-- 图片广告 -->
    <AdvPicture
      :advList="advPictureList"
      :show="advPictureShow"
      @close="advPictureClose"
    />

    <PersalePopup
      :isOpen="isHasPresale"
      :amount="advanceAmount"
      @close="closePersale"
    />
    <PayPresalePopup
      :isOpen="isPayPresale"
      :amount="balanceAmount"
      @close="closePayPersale"
    />
    <FloatingAdv />
  </div>
</template>
<script>
import AnimationTab from "./Tab/animationTab";
import CaricatureTab from "./Tab/caricatureTab";
import NovelTab from "./Tab/novelTab";
import { getPresaleList } from "@/api/home";
import { Toast } from "vant";
import PersalePopup from "@/components/PersalePopup/index.vue";
import PayPresalePopup from "@/components/PayPresalePopup/index.vue";
import AdvPicture from "@/components/AdvPicture/index.vue";
import AnnouncementText from "@/components/AnnouncementText/index.vue";
import AdvSudoku from "@/components/AdvSudoku/index.vue";
import FloatingAdv from "@/components/FloatingAdv/index.vue";
import { mapGetters } from "vuex";
import SvgIcon from "@/components/SvgIcon";

import {
  AdType,
  getAdItem,
  getAnnouncementText,
  getMarquee,
  getOfficialConfigApps,
  jumpAdv,
  MarqueeType,
} from "@/utils/getConfig";
import { judgeTime } from "@/utils/index";
import { getSessionItem, setSessionItem } from "@/utils/longStorage";
import store from "@/store";

export default {
  name: "Home",
  components: {
    FloatingAdv,
    AdvSudoku,
    AnnouncementText,
    AdvPicture,
    AnimationTab,
    CaricatureTab,
    NovelTab,
    SvgIcon,
    PersalePopup,
    PayPresalePopup,
  },
  computed: {
    ...mapGetters({
      homeTabIndex: "homeTabIndex",
      activeIndex: "activeIndex",
    }),
  },
  data() {
    return {
      currentTab: 0,
      moduleName: "漫画",
      tabs: [
        {
          title: "漫画",
        },
        {
          title: "动漫",
        },
        {
          title: "小说",
        },
      ],
      bannerList: [],
      advSudokuShow: false, //九宫格广告弹窗
      advSudokuList: [], //九宫格广告数据
      announcementTextShow: false, //公告弹窗
      announcementTextList: [], //公告数据
      advPictureShow: false, //图片广告弹窗
      advPictureList: [], //图片广告数据
      isScroll: true,
      isHasPresale: false,
      isPayPresale: false,
      advanceAmount: 0,
      balanceAmount: 0,
      activityDetail: {},
    };
  },
  activated() {
    // if (this.$route.query.t) {
    //   this.currentTab = Number(this.$route.query.t);
    // }
    // this.$nextTick(()=>{
    //   this.backTop();
    // })
  },
  created() {
    this.bannerList = getAdItem(AdType.COMMUNITY_RECOMMEND);
    this.listAdv = getAdItem(AdType.COLUMN_ADV);
    let appConfig = JSON.parse(getSessionItem("appConfig"));
    this.jingangArea = appConfig.jingangArea.list || [];
    this.getPresaleData();
    // console.log(appConfig, "-----------------");
    // 预售弹框
  },
  mounted() {
    this.advSudokuList = getOfficialConfigApps();
    this.announcementTextList = getAnnouncementText();
    this.advPictureList = getAdItem(AdType.FREE_VIDEO).reverse();
    this.advList = getAdItem(AdType.COLUMN_ADV);
    this.marqueeText = getMarquee(MarqueeType.community);
    if (
      this.advSudokuList.length > 0 &&
      getSessionItem("advSudokuPop") != "1"
    ) {
      setSessionItem("advSudokuPop", "1");
      this.advSudokuShow = true;
    } else if (
      this.announcementTextList.length > 0 &&
      getSessionItem("announcementTextPop") != "1"
    ) {
      setSessionItem("announcementTextPop", "1");
      this.announcementTextShow = true;
    } else if (
      this.advPictureList.length > 0 &&
      getSessionItem("advPicturePop") != "1"
    ) {
      setSessionItem("advPicturePop", "1");
      this.advPictureShow = true;
    }
    let data = this.$store.getters.modulesInfo;
    this.$nextTick(() => {
      window.addEventListener("scroll", this.scroll, true);
    });
  },
  methods: {
    backTop() {
      // console.log(this.keepScroll, this.activeIndex);
      let dom = document.getElementById(this.activeIndex);
      setTimeout(() => {
        dom?.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }, 500);
    },
    async getPresaleData() {
      let req = {
        pageNumber: 1,
        pageSize: 1,
      };
      let ret = await this.$Api(getPresaleList, req);
      if (ret && ret.code == 200) {
        this.activityDetail = ret.data.activityDetail;
        let appConfig = JSON.parse(getSessionItem("appConfig"));
        let data = appConfig.advanceStatus;

        if (data && data.activityStatus) {
          this.advanceAmount = data.advanceAmount || 0;
          this.balanceAmount = data.balanceAmount || 0;
          // 控制弹框显示隐藏
          if (data.status == 0) {
            this.isHasPresale =
              sessionStorage.getItem("presaleFirst") == 0 ? false : true;
          } else if (
            (data.status == 2 || data.status == 3) &&
            judgeTime(this.activityDetail.startTime)
          ) {
            this.isPayPresale =
              sessionStorage.getItem("payPresaleFirst") == 0 ? false : true;
          }
        }
      }
    },
    changeModule(index) {
      this.$store.commit("app/SET_HOMEINDEX", index);
      this.currentTab = index;
      this.moduleName = index === 1 ? "动漫" : index === 2 ? "小说" : "漫画";
    },
    changeStatus(flag) {
      this.isScroll = flag;
    },
    scroll(e) {
      // 监听滚动
      let scrollTop = e.target.scrollTop || 0;

      let scrollHeight = e.target.scrollHeight || 0;
      let clientHeight = e.target.clientHeight || 0;
      if (scrollTop === 0) {
        // 向上滚动到顶部
        this.changeStatus(true);
      } else if (scrollTop + clientHeight === scrollHeight) {
        // 向下滚动到底部
        this.changeStatus(false);
      } else if (scrollTop > this.lastScrollTop) {
        // 向下滚动
        if (scrollTop - this.lastScrollTop > 2) {
          this.changeStatus(false);
        }
      } else {
        if (this.lastScrollTop - scrollTop > 10) {
          this.changeStatus(true);
        }
        // 向上滚动
      }
      this.lastScrollTop = scrollTop;
    },
    advPictureClose() {
      /**
       * 图片广告
       */
      // this.advPictureList.pop();
      // if (this.advPictureList.length <= 0) {
      this.advPictureShow = false;
      // }
    },
    announcementTextClose() {
      /**
       * 公告弹窗关闭
       */
      this.announcementTextShow = false;

      if (
        this.advPictureList.length > 0 &&
        getSessionItem("advPicturePop") != "1"
      ) {
        setSessionItem("advPicturePop", "1");
        this.advPictureShow = true;
      }
    },
    advSudokuClose() {
      /**
       * 九宫格弹窗关闭
       */
      this.advSudokuShow = false;

      if (
        this.announcementTextList.length > 0 &&
        getSessionItem("announcementTextPop") != "1"
      ) {
        setSessionItem("announcementTextPop", "1");
        this.announcementTextShow = true;
      } else if (
        this.advPictureList.length > 0 &&
        getSessionItem("advPicturePop") != "1"
      ) {
        setSessionItem("advPicturePop", "1");
        this.advPictureShow = true;
      }
    },
    goAdv(item) {
      jumpAdv(item);
    },
    closePersale() {
      // this.isShowPersalePopup = false;
      this.isHasPresale = false;
      sessionStorage.setItem("presaleFirst", "0");
    },
    // 关闭支付预售尾款弹框
    closePayPersale() {
      this.isPayPresale = false;
      sessionStorage.setItem("payPresaleFirst", "0");
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  width: 100%;
  height: 100%;
  overflow: hidden;
  .tab-container {
    height: 100%;
    //background: #f5f5f5;
  }

  .mainBox {
    transform: translateY(0px);
    transition: transform 0.3s;
  }

  .scrollHeaderBox {
    transform: translateY(-53px);
    transition: transform 0.3s;
  }

  .tab-header {
    padding: 10px;
    padding-left: 8px;
    height: 36px;
    //background: #F5F5F5;
    //background-size: 100%;
    background: url("~@/assets/png/videoModuleBg.png") no-repeat bottom;
    display: flex;
    justify-content: space-between;
    //border-bottom: 2px solid red;
    .tab-left {
      width: 164px;
      height: 36px;
      //background: #f1f1f1;
      border-radius: 18px;
      display: flex;
      //justify-content: center;
      align-items: center;
    }
    .tab-right {
      display: flex;
      .ranking {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 17px;
        .icon {
          width: 20px;
          height: 20px;
        }

        img {
          width: 20px;
          height: 20px;
        }
      }
      .ranking:last-child {
        margin-right: 0;
      }
    }
  }
  .tab-item {
    cursor: pointer;
    //color: #000000;
    width: 63px;
    height: 32px;
    margin-right: 12px;
    //background: #f1f1f1;
    //border-radius: 16px;
    //text-align: center;
    //line-height: 32px;
    //font-size: 12px;
    //font-weight: 400;
    .ActiveIcon {
      margin-top: 2px;
      width: 63px;
      height: 28px;
    }
    .Icon {
      margin-top: 6px;
      width: 44px;
      height: 20px;
    }
  }

  .active {
    //color: #000000;
    //font-weight: bold;
    //background: #94D6DA;
  }

  .fade-enter-active,
  .fade-leave-active {
    //transition: opacity 0.5s;
  }

  .fade-enter,
  .fade-leave-to {
    //opacity: 0;
  }

  .tab-content {
    //overflow: auto;
    height: 100%;
    //height: calc(100vh - 80px);
  }

  .adImgBox {
    width: 100%;
    height: 88px;

    /deep/ img {
      object-fit: fill !important;
    }
  }

  .isFirst {
    margin-top: 85px !important;
  }
}
</style>
