<template>
  <div class="waterfallItem">
    <PullRefresh
      :disabled="isRefreshDisable"
      v-if="!isLoading"
      :loading="loading"
      :refreshing="refreshing"
      :finished="finished"
      @onLoad="onLoad"
      @onRefresh="onRefresh"
      :isNoData="isNoData"
      :error="error"
      :hasAdvPagination="true"
    >
      <!-- 轮播图5个 -->
      <div
        class="fiveGrid"
        v-if="bannerList && bannerList.length"
      >
        <div
          class="rectAdvBox"
          @click="goAdv(item)"
          v-for="item in bannerList.slice(0, 10)"
          :key="item.id"
        >
          <ImgDecypt class="rectAdvIcon" :src="item.cover" />
          <div class="rectName">{{ item.title }}</div>
        </div>
      </div>

      <div class="notice" v-if="isNotice && marqueeText.content" @click="goAdv(marqueeText)"
      >
        <img src="~@/assets/png/notice.png" />
        <van-notice-bar
          :key="isNotice"
          class="noticeBar"
          speed="20"
          color="rgb(0,0,0)"
          background="rgba(0,0,0,0)"
          :text="marqueeText.content"
        />
      </div>
      <div class="waterfall">
        <div
          class="photoBox"
          v-for="(item, index) in videoList"
          :key="item.id"
          :style="computedRatio(item)"
          @click="openDetails(videoList, item, index)"
        >
          <div
            class="coverBox"
            v-if="item.verticalCover || item.horizontalCover"
          >
            <ImgDecypt
              class="cover"
              :src="
                item.verticalCover ? item.verticalCover : item.horizontalCover
              "
              :key="
                item.verticalCover ? item.verticalCover : item.horizontalCover
              "
              :style="{ height: computedCoverHeight(item) + 'px' }"
            />
            <div class="label" :class="handelLabel(item.permission)"></div>
            <div class="statusBox">
              {{ item.updateStatus == 2 ? "已完结·" : "更新中·"
              }}{{ item.totalEpisode }}话
            </div>
          </div>
          <div class="titleBox">
            <div class="title ellipsis">
              <span>{{ item.title }}</span>
            </div>
            <div class="info flex-between">
              <div class="tags" v-if="item.tagDetails">
                <div
                  class="tag"
                  v-for="tag in item.tagDetails.slice(0, 3)"
                  :key="tag.id"
                >
                  {{ tag.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PullRefresh>
    <Loading v-else />
  </div>
</template>
<script>
import PullRefresh from "@/components/PullRefresh/index.vue";
import { querySubModuleList } from "@/api/app";
import {
  AdType,
  getAdItem,
  getMarqueeData,
  jumpAdv,
  MarqueeType,
  openDetails,
} from "@/utils/getConfig";
import ImgDecypt from "@/components/ImgDecypt/index.vue";
import Loading from "@/components/Loading/index.vue";
import selectTag from "@/views/publish/selectTag/index.vue";
export default {
  name: "waterfallItem",
  props: {
    index: {
      type: Number,
      default: 0,
    },
    moduleName: {
      type: String,
    },
    subModuleId: {
      type: String,
    },
    isNotice: {
      type: Number,
    },
  },
  components: {
    Loading,
    PullRefresh,
    ImgDecypt,
  },
  data() {
    return {
      tabActive: 0,
      pageNumber: 1,
      pageSize: 10,
      isRefreshDisable: false,
      isLoading: true,
      loading: true,
      refreshing: false,
      finished: false,
      isNoData: false,
      error: false,
      videoList: [],
      ratio: window.outerWidth / 375,
      bannerList: [],
      marqueeText: null,
    };
  },
  mounted() {
    this.bannerList = getAdItem(AdType.HOMEADV);
    if (this.isNotice) {
      this.marqueeText = getMarqueeData(MarqueeType.community);
    }
    this.querySubModuleList("refresh");
  },
  computed: {
    computedRatio() {
      let height = 12;
      return (item) => {
        return {
          gridRowEnd: `span ${Math.floor(
            this.computedCoverHeight(item) / 5.4 + height
          )}`,
        };
      };
    },
  },
  methods: {
    openDetails,
    computedCoverHeight(item) {
      if (item.verticalCover) {
        return 180 / item.verticalRatio;
      } else {
        return 94 / item.horizontalRatio;
      }
    },
    handelLabel(permission) {
      if (permission == 1) {
        return "goldLabelIcon";
      } else if (permission == 2) {
        return "freeLabelIcon";
      } else if (permission == 0) {
        return "vipLabelIcon";
      }
    },
    goAdv(item) {
      jumpAdv(item);
    },
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.pageNumber++;
      }
      this.querySubModuleList();
    },
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.querySubModuleList("refresh");
    },
    async querySubModuleList(type) {
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        subModuleId: this.subModuleId,
      };
      let res = await this.$Api(querySubModuleList, req);
      this.loading = false;
      this.refreshing = false;
      this.isLoading = false;
      const list = res.data.allMediaInfo || [];
      console.log(list, "daaaaaaaaaaa");
      if (type === "refresh") {
        this.videoList = list;
      } else {
        this.videoList = this.videoList.concat(list);
      }
      if (this.pageNumber === 1 && this.videoList.length === 0) {
        this.isNoData = true;
      }
      if (!res.data.hasNext) {
        this.finished = true;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.waterfallItem {
  height: 100%;
  background: #f5f5f5;
  //padding: 10px 16px 100px 12px;
  box-sizing: border-box;

  .fiveGrid {
    padding: 0 10px;
    margin-top: 18px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-row-gap: 20px;
    grid-column-gap: 12px;
    .rectAdvBox {
      width: 60px;
      .rectAdvIcon {
        height: 60px;
        width: 60px;
        border-radius: 8px;
        overflow: hidden;
      }
      .rectName {
        font-size: 12px;
        color: #333;
        margin-top: 4px;
        overflow: hidden;
        white-space: nowrap;
        text-align: center;
      }
    }
  }

  .notice {
    margin: 20px 10px 0 10px;
    padding-left: 16px;
    background: #ffffff;
    color: #999999;
    height: 36px;
    //border-radius: 6px;
    display: flex;
    align-items: center;

    img {
      width: 16px;
      height: 16px;
    }

    /deep/ .van-notice-bar {
      padding: 0;
      font-size: 12px;
    }

    .noticeBar {
      width: 100%;
      height: 100%;
      color: #999999 !important;
      padding: 0 16px 0 10px;
    }
  }

  .waterfall {
    padding: 0 10px;
    padding-top: 10px;
    display: grid;
    grid-auto-rows: 5px;
    grid-template-columns: repeat(auto-fill, 174px);
    align-items: start;
    justify-content: space-between;

    .photoBox {
      width: 174px;
      display: flex;
      flex-wrap: wrap;
      background: #ffffff;
      border-radius: 8px;

      .coverBox {
        position: relative;

        .label {
          position: absolute;
          top: 0;
          left: 0;
          width: 32px;
          height: 20px;
        }

        .goldLabelIcon {
          background: url("~@/assets/png/goldLabelIcon.png");
          background-size: 100% 100%;
        }

        .freeLabelIcon {
          background: url("~@/assets/png/freeLabelIcon.png");
          background-size: 100% 100%;
        }

        .vipLabelIcon {
          background: url("~@/assets/png/vipLabelIcon.png");
          background-size: 100% 100%;
        }

        .statusBox {
          position: absolute;
          right: 5px;
          bottom: 5px;
          padding: 0 9px;
          box-sizing: border-box;
          height: 20px;
          border-radius: 20px;
          background: rgba(255, 255, 255, 0.5);
          backdrop-filter: blur(1.0499999523162842px);
          font-size: 10px;
          font-style: normal;
          font-weight: 500;
          color: #3e169d;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .cover {
        width: 174px;
        border-radius: 8px;
        overflow: hidden;
      }

      .titleBox {
        width: 174px;

        .title {
          padding-top: 4px;
          width: 174px;
          color: #000000;
          font-size: 12px;
          font-weight: 600;
          line-height: 20px;
          word-wrap: break-word;

          span {
            padding-left: 5px;
          }
        }

        .info {
          align-items: center;
          padding: 0 5px 5px;

          .tags {
            display: flex;
            flex-wrap: wrap;
            font-size: 10px;
            color: #999;
            margin-top: 5px;

            .tag {
              margin-right: 6px;
            }
          }

          span {
            font-size: 10px;
            color: #777777;
            margin-left: 2px;
          }
        }
      }
    }
  }
}
</style>
