import { render, staticRenderFns } from "./VerticleTwo.vue?vue&type=template&id=398e2958&scoped=true&"
import script from "./VerticleTwo.vue?vue&type=script&lang=js&"
export * from "./VerticleTwo.vue?vue&type=script&lang=js&"
import style0 from "./VerticleTwo.vue?vue&type=style&index=0&id=398e2958&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "398e2958",
  null
  
)

export default component.exports