<template>
  <div class="videoCollectionItem">
    <!--      <div class="swiperBox" v-if="bannerList && bannerList.length">-->
    <!--        <van-swipe class="swipe" :autoplay="3000" indicator-color="#FECD55">-->
    <!--          <van-swipe-item-->
    <!--              v-for="(item, index) in bannerList"-->
    <!--              :key="index"-->
    <!--              @click="goAdv(item)"-->
    <!--          >-->
    <!--            <ImgDecypt :src="item.cover" :key="item.cover" />-->
    <!--          </van-swipe-item>-->
    <!--        </van-swipe>-->
    <!--      </div>-->
    <!-- 轮播图5个 -->
    <div class="fiveGrid" v-if="bannerList && bannerList.length">
      <div
        class="rectAdvBox"
        @click="goAdv(item)"
        v-for="item in bannerList.slice(0, 10)"
        :key="item.id"
      >
        <ImgDecypt class="rectAdvIcon" :src="item.cover" />
        <div class="rectName">{{ item.title }}</div>
      </div>
    </div>

    <div
      class="notice"
      v-if="isNotice && marqueeText.content"
      @click="goAdv(marqueeText)"
    >
      <img src="~@/assets/png/notice.png" />
      <van-notice-bar
        :key="isNotice"
        class="noticeBar"
        speed="20"
        color="rgb(0,0,0)"
        background="rgba(0,0,0,0)"
        :text="marqueeText.content"
      />
    </div>
    <div v-if="num == 3">
      <van-tabs class="tabs" v-model="active" sticky>
        <van-tab title="最新连载">
          <ListItem
            :subModuleId="subModuleId"
            :moduleSort="1"
            :showType="showType"
            :isAcg="isAcg"
            :isNotice="0"
            :isBanner="false"
          ></ListItem>
        </van-tab>
        <van-tab title="精彩完结">
          <ListItem
            :subModuleId="subModuleId"
            :moduleSort="7"
            :showType="showType"
            :isAcg="isAcg"
            :isNotice="0"
            :isBanner="false"
          ></ListItem>
        </van-tab>
        <van-tab title="热门推荐">
          <ListItem
            :subModuleId="subModuleId"
            :moduleSort="2"
            :showType="showType"
            :isAcg="isAcg"
            :isBanner="false"
          ></ListItem>
        </van-tab>
      </van-tabs>
    </div>
    <div v-else>
      <van-tabs class="tabs" v-model="active" sticky>
        <van-tab title="最新">
          <ListItem
            :subModuleId="subModuleId"
            :moduleSort="1"
            :showType="showType"
            :isAcg="isAcg"
            :isNotice="0"
            :isBanner="false"
          ></ListItem>
        </van-tab>
        <van-tab title="最热">
          <ListItem
            :subModuleId="subModuleId"
            :moduleSort="2"
            :showType="showType"
            :isAcg="isAcg"
            :isNotice="0"
            :isBanner="false"
          ></ListItem>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>
<script>
import PullRefresh from "@/components/PullRefresh/index.vue";
import FourGridHorizontal from "@/components/FourGridHorizontal/index.vue";
import { querySubModuleList } from "@/api/app";
import {
  AdType,
  getAdItem,
  getMarqueeData,
  jumpAdv,
  MarqueeType,
} from "@/utils/getConfig";
import ImgDecypt from "@/components/ImgDecypt/index.vue";
import ListItem from "../ListItem/index.vue";

export default {
  name: "VideoCollectionItem",
  props: {
    moduleName: {
      type: String,
    },
    index: {
      type: Number,
      default: 0,
    },
    subModuleId: {
      type: String,
    },
    num: {
      type: Number,
    },
    showType: {
      type: Number,
    },
    isAcg: {
      type: Boolean,
    },
    isNotice: {
      type: Number,
    },
  },
  components: {
    ListItem,
    // PullRefresh,
    ImgDecypt,
  },
  data() {
    return {
      active: 0,
      pageNumber: 1,
      pageSize: 10,
      isRefreshDisable: false,
      isLoading: true,
      loading: true,
      refreshing: false,
      finished: false,
      isNoData: false,
      error: false,
      tags: [],
      bannerList: [],
      jingangArea: [],
      marqueeText: null,
    };
  },
  mounted() {
    // this.querySubModuleList('refresh');
    this.bannerList = getAdItem(AdType.HOMEADV);
    if (this.isNotice) {
      this.marqueeText = getMarqueeData(MarqueeType.community);
    }
    // this.marqueeText = getMarquee(MarqueeType.community);
  },
  methods: {
    goAdv(item) {
      jumpAdv(item);
    },
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.pageNumber++;
      }
      this.querySubModuleList();
    },
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.querySubModuleList("refresh");
    },
    async querySubModuleList(type) {
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        subModuleId: this.subModuleId,
      };
      let res = await this.$Api(querySubModuleList, req);
      this.loading = false;
      this.refreshing = false;
      this.isLoading = false;
      const list = res.data.allMediaInfo || [];
      if (type === "refresh") {
        this.tags = list;
      } else {
        this.tags = this.tags.concat(list);
      }
      if (this.pageNumber === 1 && this.tags.length === 0) {
        this.isNoData = true;
      }
      if (!res.data.hasNext) {
        this.finished = true;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.videoCollectionItem {
  height: 100%;
  .swiperBox {
    .swipe {
      width: 100vw;
      height: calc(100vw / 2.4);

      .van-swipe-item {
        width: 100%;
        color: #fff;
        font-size: 20px;
        line-height: 150px;
        text-align: center;
        background-color: rgb(43, 39, 72);
      }

      /deep/ .van-swipe__indicators {
        left: auto;
        right: 0;
        align-items: center;
      }

      /deep/ .van-swipe__indicator {
        width: 6px;
        height: 6px;
        background-color: rgba(148, 214, 218, 0.3);
      }

      /deep/ .van-swipe__indicator--active {
        width: 9px;
        height: 9px;
        background-color: #94d6da !important;
      }

      /deep/ img {
        object-fit: fill !important;
      }

      /deep/ .van-image__error {
        border-radius: 0;
      }
    }
  }

  .fiveGrid {
    padding: 0 10px;
    margin-top: 18px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-row-gap: 20px;
    grid-column-gap: 12px;
    .rectAdvBox {
      width: 60px;
      .rectAdvIcon {
        height: 60px;
        width: 60px;
        border-radius: 8px;
        overflow: hidden;
      }
      .rectName {
        font-size: 12px;
        color: #333;
        margin-top: 4px;
        overflow: hidden;
        white-space: nowrap;
        text-align: center;
      }
    }
  }

  .notice {
    margin: 20px 10px 0 10px;
    padding-left: 16px;
    background: #ffffff;
    color: #999999;
    height: 36px;
    //border-radius: 6px;
    display: flex;
    align-items: center;

    img {
      width: 16px;
      height: 16px;
    }

    /deep/ .van-notice-bar {
      padding: 0;
      font-size: 12px;
    }

    .noticeBar {
      width: 100%;
      height: 100%;
      color: #999999 !important;
      padding: 0 16px 0 10px;
    }
  }

  .barTopic {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    // justify-content: space-between;
    //padding: 12px 0;
    padding: 0 10px;
    margin-top: 20px;
    font-size: 10px;
    color: #000000;
    text-align: center;
    //border-bottom: solid rgb(230, 230, 230) 1px;
    box-sizing: border-box;
    overscroll-behavior: contain;

    .barTopicItem {
      //width: 86px;
      flex: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-right: 24px;
    }

    .barTopicItem:last-child {
      //padding-right: 30px;
    }

    .topicImg {
      width: 50px;
      height: 50px;
      margin-bottom: 16px;
    }

    img {
      width: 42px;
      height: 42px;
    }
  }

  .tabs {
    margin-top: 20px;
    //padding: 0 16px;

    /deep/ .van-tabs__wrap {
      background-image: none !important;
      background: #f0f0f3;
      border-radius: 8px;
      height: 33px !important;
      border: solid 2px #ffffff;
      margin: 12px 16px;
      padding: 0;
    }

    /deep/ .van-tabs__nav {
      background: transparent;
      margin-left: 0;
    }

    /deep/ .van-tab {
      padding: 0;
      border: solid 2px #f0f0f3;
    }

    /deep/ .van-tab--active {
      border-radius: 8px;
      border: solid 2px #e6e6e9;
    }

    /deep/ .listItem {
      height: 100%;
    }

    /deep/ .my-refresh {
      overflow-y: visible;
    }

    /deep/ .van-tabs__line {
      display: none;
    }

    /deep/ .van-sticky--fixed {
      background: #ffffff;
      top: 43px;
      height: 50px;
      padding-top: 18px;
    }
  }
}
</style>
