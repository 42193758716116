<template>
  <div class="verticleThree">
    <div class="videoItem">
      <div class="titleBar">
        <div>
          <p class="titleName">{{ videoInfoData.sectionName }}</p>
          <p class="titleDetails">{{ videoInfoData.sectionDesc }}</p>
        </div>
        <div class="moreRight" @click="moreMore">
          <span class="moreRightTitle">更多</span>
          <img src="@/assets/png/moreRight.png" />
        </div>
      </div>
      <div class="videoInfo">
        <VerticleThreeList
          :videoList="videoInfoData && videoInfoData['allMediaInfo'] ? videoInfoData['allMediaInfo'].slice(0,num) : []"
        ></VerticleThreeList>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 竖版视频列表（三）
 *  ps:
 *      direction = 0,  (一行)
 *      direction = 1   (两行)
 */
import VerticleThreeList from "@/components/VideoList/VerticleThreeList";
export default {
  name: "VerticleThree",
  components: {
    VerticleThreeList,
  },
  props: {
    videoInfoData: {
      type: Object,
    },
    num:{
      type:Number
    },
    tabNmae: String,
  },
  methods: {
    moreMore() {
      this.$router.push({
        path: "topicPage",
        query: {
          topicId: this.videoInfoData.sectionID,
          name: this.videoInfoData.sectionName,
          format: 0,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.verticleThree {
 padding: 0 10px;
  .videoItem {
    border-bottom: solid 1px #e6e6e6;
    padding-bottom: 12px;
    margin-top: 12px;

    .titleBar {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .titleName {
        font-size: 18px;
        color: #000000;
        font-weight: bold;
      }

      .titleDetails {
        font-size: 12px;
        color: #999999;
      }

      .moreRight {
        color: #777777;

        .moreRightTitle {
          font-size: 14px;
          vertical-align: middle;
        }

        img {
          width: 20px;
          height: 20px;
         vertical-align: middle;
        }
      }
    }

    .videoInfo {
      margin-top: 9px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
}
</style>
