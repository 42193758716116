<template>
  <div class="verticleTwo">
    <div class="videoItem">
      <div class="titleBar">
        <div>
          <p class="titleName">{{ videoInfoData.name }}</p>
          <p class="titleDetails">{{ videoInfoData.desc }}</p>
        </div>
        <div class="moreRight" @click="moreMore">
          <!-- <span class="moreRightTitle">更多</span> -->
          <img src="@/assets/png/moreRight.png" />
        </div>
      </div>
      <div class="videoInfo">
        <div
          class="videoInfoItem"
          v-for="(item, index) in videoInfoData['MediaList'].slice(0, 4)"
          :key="index"
        >
          <!--                   <div class="videoLabel vipLabel" v-if="item.permission == 0">-->
          <!--                        <span>VIP</span>-->
          <!--                    </div>-->

          <div class="videoLabel beanLabel" v-if="item.permission == 1">
            <svg-icon class="Golden" iconClass="Golden"></svg-icon>
            {{
              (item.kind == 4 || item.kind == 1) && item.price > 0
                ? item.price
                : ""
            }}金豆
          </div>

          <div class="videoLabel freeLabel" v-if="item.permission == 2">
            <span>免费</span>
          </div>

          <div class="previewImg" @click="jump(item, index)">
            <ImgDecypt :src="item['verticalCover']" />

            <div class="videoTxt">
              <p class="videoTxtTitle">{{ item.title }}</p>
              <p>
                <span
                  v-for="(itemTag, indexTag) in item['tagNames'].slice(0, 3)"
                  :key="indexTag"
                  class="videoTxtTag"
                  @click.stop="tagJump(item, indexTag, itemTag)"
                >
                  {{ itemTag }}
                  <br v-if="(indexTag + 1) % 5 == 0" />
                </span>
              </p>
              <p
                class="updatedDesc"
                :class="{ updateEnd: item.updateStatus == 2 }"
              >
                {{ item.updatedDesc }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 竖版视频列表（二）
 * ps:
 *      direction = 2   (两行)
 */
import ImgDecypt from "@/components/ImgDecypt";
import store from "@/store/index.js";
export default {
  name: "VerticleTwo",
  components: {
    ImgDecypt,
  },
  props: {
    videoInfoData: {
      type: Object,
    },
    tabNmae: String,
  },
  methods: {
    moreMore() {
      this.$router.push({
        path: "topicPage",
        query: {
          topicId: this.videoInfoData.id,
          zone: this.videoInfoData.zone,
          name: this.videoInfoData.name,
          format: 0,
          tabNmae: this.tabNmae,
        },
      });
    },
    jump(item, index) {
      let kind = item["kind"];
      if (kind != null) {
        switch (kind) {
          case 0:
          case 2:
          case 3:
          case 6:
            //跳转视频
            this.$router.push({
              path: "/videoDetailsPage",
              query: {
                id: item.id,
                kind: kind,
              },
            });
            break;
          case 4:
          case 1:
            store.commit("pageRefresh/SET_REFRESHFLAG", {
              name: "novelDetailsPage",
              flag: false,
            });
            //小说/漫画
            this.$router.push({
              path: "/novelDetailsPage",
              query: {
                id: item.id,
                kind: kind,
                price: item.price,
              },
            });
            break;
          case 5:
            var list = JSON.stringify(this.videoInfoData["MediaList"]);
            //图集
            this.$router.push({
              path: "/portrayPage",
              query: {
                id: item.id,
                list: this.encodeBase64(list),
                phoneIndex: index,
              },
            });
            break;
        }
      }
    },
    tagJump(item, index, itemTag) {
      let tagInfo = {
        id: item.tags[index],
        name: itemTag,
      };
      // console.log(this.$route.path)
      if (this.$route.path == "/tagList") {
        this.$router.replace({
          path: "/tagList",
          query: {
            tagData: this.encodeBase64(encodeURI(JSON.stringify(tagInfo))),
            kind: item.kind,
            t: Date.now(),
          },
        });
      } else {
        this.$router.push({
          path: "/tagList",
          query: {
            tagData: this.encodeBase64(encodeURI(JSON.stringify(tagInfo))),
            kind: item.kind,
          },
        });
      }
    },
    // 转base64
    encodeBase64(str) {
      let base64Str = Buffer.from(str, "utf-8").toString("base64");
      return base64Str;
    },
  },
};
</script>

<style lang="scss" scoped>
.verticleTwo {
  .videoItem {
    border-bottom: solid 1px rgb(230, 230, 230);
    padding-bottom: 12px;
    margin-top: 12px;

    .titleBar {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .titleName {
        font-size: 18px;
        color: #000000;
        font-weight: bold;
      }

      .titleDetails {
        font-size: 12px;
        color: #999999;
      }

      .moreRight {
        color: #000000;

        .moreRightTitle {
          font-size: 14px;
          margin-right: 6.1px;
        }

        img {
          width: 12px;
          height: 12px;
        }
      }
    }

    .videoInfo {
      margin-top: 9px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .videoInfoItem {
        width: 48.5%;
        margin-bottom: 12px;
        position: relative;

        .videoLabel {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          padding: 1px 4px;
          // border-radius: 3px;
          z-index: 1;
          top: 0px;
          left: 10px;
          text-wrap: none;
        }

        .vipLabel {
          color: rgb(102, 61, 0);
          background-image: linear-gradient(
            to right,
            rgb(255, 231, 168),
            rgb(242, 202, 91)
          );
        }

        .beanLabel{
          font-size: 10px;
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
          color: rgb(255, 255, 255);
          background: rgb(255, 16, 96);
          .Golden {
            width: 14px;
            height: 14px;
          }
        }

        .freeLabel {
          width: 30px;
          height: 16px;
          font-size: 10px;
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
          color: rgb(255, 255, 255);
          background-image: linear-gradient(
            to right,
            rgb(40, 249, 218),
            rgb(86, 143, 254)
          );
        }

        .previewImg {
          width: 100%;
          height: 252px;
          border-radius: 8px;
          background: #ffffff;

          // /deep/ .van-image{
          //     border-radius: 6px;
          // }

          /deep/ .van-image__img {
            border-radius: 8px;
          }
        }

        .videoTxt {
          position: relative;
          height: 60px;
          top: -60px;
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
          background: rgba(0, 0, 0, 0.6);
          padding: 3px 12px 0;
          box-sizing: border-box;
          // border-bottom-left-radius: 6px;
          // border-bottom-right-radius: 6px;

          .videoTxtTitle {
            font-size: 12px;
            color: #ffffff;
            font-weight: 400;
          }

          .videoTxtTag {
            font-size: 10px;
            color: #ffffff;
            margin-right: 6px;
          }
        }

        .updatedDesc {
          font-size: 8px;
          color: #00ffff;
        }
        .updateEnd {
          color: #fff;
        }
      }
    }
  }
}
</style>
