<template>
  <div class="noveItem overflow-y-auto" :id="scrollId">
    <!-- <HomeItem :subModuleId="item.id" v-if="item.showType === 1"></HomeItem> -->
    <VideoModuleItem
      :isNotice="noticeKey"
      :jingangType="1"
      :isAcg="true"
      :bannerType="bannerType"
      :subModuleId="item.id"
      :advType="advType"
      :moduleSort="1"
      :index="index"
      v-if="item.showType === 1"
    ></VideoModuleItem>
    <ListItem
      :isNotice="noticeKey"
      :subModuleId="item.id"
      :moduleSort="1"
      :index="index"
      v-else-if="item.showType === 8"
    ></ListItem>
    <ListItem
      :isNotice="noticeKey"
      :subModuleId="item.id"
      :moduleSort="1"
      :index="index"
      v-else-if="item.showType === 9"
      :showType="3"
    ></ListItem>
    <videoTabItem
      :isNotice="noticeKey"
      :subModuleId="item.id"
      v-else-if="item.showType === 10"
      :num="3"
      :showType="3"
      :isAcg="isAcg"
      :index="index"
    ></videoTabItem>
    <videoTabItem
      :isNotice="noticeKey"
      :subModuleId="item.id"
      v-else-if="item.showType === 11"
      :num="2"
      :showType="3"
      :isAcg="isAcg"
      :index="index"
    ></videoTabItem>
    <WaterfallItem
      :isNotice="noticeKey"
      :subModuleId="item.id"
      :index="index"
      v-else-if="item.showType === 12"
    ></WaterfallItem>
  </div>
</template>
<script>
import { AdType } from "@/utils/getConfig";
import HomeItem from "../HomeItem/index.vue";
import VideoModuleItem from "@/views/home/videoModuleItem/index.vue";
import WaterfallItem from "@/views/home/WaterfallItem/index.vue";
import ListItem from "@/views/home/ListItem/index.vue";
import videoTabItem from "@/views/home/videoTabItem/index.vue";

export default {
  components: {
    videoTabItem,
    ListItem,
    WaterfallItem,
    VideoModuleItem,
    // HomeItem
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: 0,
    },
    noticeKey: {
      type: Number,
      default: 1,
    },
    scrollId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isAcg: true,
      bannerType: AdType.HOMEADV,
      advType: AdType.COLUMN_ADV,
    };
  },
};
</script>

<style scoped lang="scss">
.noveItem {
  height: calc(100vh - 130px);
}
</style>
